import {
  Calendar,
  FileText,
  ChevronRight,
  Clock,
  CalendarCheck,
  Edit,
  DollarSign,
  Check,
  X,
  PenToolIcon as Tool,
} from "lucide-react";
import { useEffect, useState, useRef } from "react";
import "../../Assests/css/Maintenance.css";
import Layout from "../Layout/Layout";
// import { DatePicker } from 'antd';
import { useLocation } from "react-router-dom";
import { handleError } from "../../CommonJquery/CommonJquery";
import { Card, Button } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  APL_LINK,
  maintenance_data_user,
  server_post_data,
} from "../../ServiceConnection/serviceconnection";
import MaintenanceRecord from "../Sections/MaintenanceRecord.js";
import PaymentHistrory from "../Sections/PaymentHistrory";
export default function MaintenanceDashboard() {
  const datePickerRef = useRef(null);
  const [showMaintenanceModal, setShowMaintenanceModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const [activeTab, setActiveTab] = useState("payments");
  const location = useLocation();
  const [carData, setcarData] = useState([]);
  const [paymentHistryData, setpaymentHistryData] = useState([]);
  const [maintainRecord, setmaintainRecord] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  // let newid;
  const carData2 = {
    from_date: "2025-04-17"
  };
  useEffect(() => {

    if (location.state) {
      const passedData = location.state;
      setcarData(passedData.bookingData);
      let newid = passedData.bookingData.primary_id;
      console.log("newid", newid);
      if (newid !== undefined) {
        master_data_get(newid);
      }
    }
  }, [location]);

  const master_data_get = async (newid) => {
    const fd = new FormData();
    fd.append("primary_id", newid);
    await server_post_data(maintenance_data_user, fd)
      .then((Response) => {
        if (Response.error) {
          handleError(Response.message);
        } else {
          setpaymentHistryData(Response.message.payment);
          setmaintainRecord(Response.message.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleNext = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const toggleMaintenanceModal = () => {
    setShowMaintenanceModal(!showMaintenanceModal);
    if (!showMaintenanceModal) {
      setCurrentStep(0);
    }
  };
  const [selectedDate, setSelectedDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [formattedDate, setFormattedDate] = useState("");
  const [buttonStage, setButtonStage] = useState("initial");
  // const handleButtonClick = () => {
  //   setShowDatePicker(!showDatePicker);
  // };
  const formatDate = (date) => {
    if (!date) return "";
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target)
    ) {
      setShowDatePicker(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleEditClick = () => {
    setShowDatePicker(true);
    setButtonStage("picking");
    setIsEditing(true);
  };

  const handleRescheduleClick = () => {
    setButtonStage("initial");
    setSelectedDate(null);
    setFormattedDate("");
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setShowDatePicker(false);
    setFormattedDate(formatDate(date));
    setIsEditing(false);
  };
  const handleDateSelect = (date) => {
    setShowDatePicker(false);
    setFormattedDate(formatDate(date));
    setIsEditing(false);
  };
  const handleMainButtonClick = () => {
    if (buttonStage === "initial") {
      setShowDatePicker(true);
      setButtonStage("picking");
    } else if (buttonStage === "picking") {
      setButtonStage("scheduled");
      setShowDatePicker(false);
      console.log("Date saved:", formattedDate);
    } else {
      setButtonStage("initial");
    }
  };

  const handleCLoseDatePicker = () => {
    setShowDatePicker(false);
  };

  const handleSaveDate = () => {
    if (selectedDate) {
      console.log("Date saved:", formatDate(selectedDate));
      setFormattedDate(formatDate(selectedDate));
      // Here you would typically call an API to save the date
    }
    setIsEditing(false);
    setShowDatePicker(false);
    setButtonStage("scheduled");
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setShowDatePicker(false);
  };


  const [duration, setDuration] = useState('');
  console.log("duration", duration);

  useEffect(() => {
    // Function to calculate the duration between dates
    const calculateDuration = (startDate) => {
      const today = new Date();
      const fromDate = new Date(startDate);  // Ensure proper date object conversion

      // If from_date is in the future, display "No Pickup"
      if (fromDate > today) {
        return 'No Pickup';
      }

      // Calculate the difference in time
      const timeDiff = today - fromDate;

      // Convert time difference to days
      const days = Math.floor(timeDiff / (1000 * 3600 * 24));

      let result = '';

      // Logic for days, weeks, and months
      if (days < 7) {
        result = `${days} day${days > 1 ? 's' : ''}`;
      } else if (days >= 7 && days < 30) {
        const weeks = Math.floor(days / 7);
        const remainingDays = days % 7;
        result = `${weeks} week${weeks > 1 ? 's' : ''}`;
        if (remainingDays > 0) {
          result += ` ${remainingDays} day${remainingDays > 1 ? 's' : ''}`;
        }
      } else if (days >= 30 && days < 365) {
        const months = Math.floor(days / 30);
        const remainingDays = days % 30;
        result = `${months} month${months > 1 ? 's' : ''}`;
        if (remainingDays > 0) {
          result += ` ${remainingDays} day${remainingDays > 1 ? 's' : ''}`;
        }
      } else {
        const years = Math.floor(days / 365);
        const remainingDays = days % 365;
        const months = Math.floor(remainingDays / 30);
        const remainingDaysInMonth = remainingDays % 30;
        result = `${years} year${years > 1 ? 's' : ''}`;
        if (months > 0) {
          result += ` ${months} month${months > 1 ? 's' : ''}`;
        }
        if (remainingDaysInMonth > 0) {
          result += ` ${remainingDaysInMonth} day${remainingDaysInMonth > 1 ? 's' : ''}`;
        }
      }

      return result;
    };

    // Set the calculated duration to state
    const duration = calculateDuration(carData.from_date);
    setDuration(duration);
  }, [carData.from_date]);

  return (
    <Layout>
      <div className="mainTainceContrr container-lg">
        <div className="dashboard-container">
          <div className="car-profile-card">
            <div className="car-profile-header">
              <div className="car-brand-logo">
                {/* <img src={dummYImg} alt="Car Brand Logo" /> */}
                <img
                  src={`${APL_LINK}assets/carimage/${carData.image}`}
                  alt="Car"
                  className="car-image carImageBooking"
                />
                {/* <div className="car-brand-badge">{carDetails.brand}</div> */}
              </div>
              <div className="car-details">
                <div className="car-model-info">
                  <h2>
                    {carData.car_name}
                    {/* <span className="car-year">{carDetails.year}</span> */}
                  </h2>
                </div>
                <div className="car-owner">
                  {/* <div className="owner-name">
                    <User className="icon" />
                    <span>
                      {carData.first_name} {carData.last_name}
                    </span>
                  </div>
                  <div className="pickup-date">
                    <Phone className="icon" />
                    <span>Mo: {carData.mobile_no}</span>
                  </div> */}
                  <div className="pickup-date">
                    <Clock className="icon" />
                    <span>Pickup: {carData.from_date}</span>
                  </div>
                  <div className="pickup-date">
                    <DollarSign className="icon" />
                    <span>Rental Rate: {carData.payment_package}</span>
                  </div>
                  <div className="pickup-date">
                    <CalendarCheck className="icon" />
                    <span>Rental Duration: {duration}</span>

                  </div>
                </div>
                <div className="car-contact-details">
                  {/* <div className="contact-item">
                    <Mail className="icon" />
                    <span>{carData.email}</span>
                  </div> */}
                  <div className="contact-item">
                    <FileText className="icon" />
                    <span>License: {carData.driving_licence}</span>
                  </div>
                  <div className="contact-item">
                    <FileText className="icon" />
                    <span>VIN Number: {carData.vin}</span>
                  </div>
                  <div className="contact-item">
                    <FileText className="icon" />
                    <span>
                      Insurance Policy Number: {carData.insurance_policy}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="car-profile-actions">
              <button
                className="action-button primary"
                onClick={toggleMaintenanceModal}
              >
                <Plus className="icon" />
                Add Maintenance
              </button>
            </div> */}
          </div>
          <div className="Maintn_PaymentContrr">
            <div className="row">
              <div className="col-lg-6 mb-3">
                <div className="upcoming-maintenance">
                  <div>
                    <div className="upComing_data">
                      {" "}
                      <Clock size={19} style={{ marginRight: "8px", color: "#856505" }} />

                      {buttonStage === "initial" && (
                        <div className="upcoming-content">
                          <h4>Upcoming Maintenance</h4>
                        </div>
                      )}
                      {buttonStage === "picking" && (
                        <div className="upcoming-content">
                          <h4>Upcoming Maintenance</h4>
                        </div>
                      )}
                      {buttonStage === "scheduled" && (
                        <div className="upcoming_content2">
                          <h4>Upcoming Maintenance</h4>
                        </div>
                      )}
                    </div>
                    {/* <div className="Schedule_icn_txt">
                      <span

                      >
                        <Calendar style={{ height: '12px', width: '12px', marginRight: '4px' }} />
                        Scheduled
                      </span>
                    </div> */}

                    {buttonStage === "initial" && (
                      <p>
                        Regular service scheduled for{" "}
                        <span className="highlight-date">
                          {carData.next_maintenance_date}
                        </span>
                      </p>
                    )}

                    {buttonStage === "picking" && (
                      <p>
                        Regular service scheduled for{" "}
                        <span className="highlight-date">
                          {carData.next_maintenance_date}
                        </span>
                      </p>
                    )}

                    {buttonStage === "scheduled" && (
                      <div className="upcoming_content2">
                        <p className="ScheduleSHowClass">
                          Service Rescheduled for{" "}
                          <span className="highlight-date">
                            {carData.next_maintenance_date}
                          </span>
                        </p>
                      </div>
                    )}

                    {buttonStage === "scheduled" && (
                      <>
                        {/* <div className="succes_fully_reschdule">
                          <div className="dot"></div>
                          <p> Successfully rescheduled</p>
                        </div> */}
                        <p className="Maintxt">
                          {/* <Clock size={15} style={{ marginRight: "5px" }} /> */}
                          Your maintenance has been rescheduled. We'll notify
                          you 24 hours before the service.
                        </p>
                      </>
                    )}
                    {buttonStage === "initial" && (
                      <>
                        {formattedDate && (
                          <p className="">
                            <Calendar
                              className="text-amber-600"
                              size={14}
                              style={{ marginRight: "5px" }}
                            />
                            <span>Selected Date :-{formattedDate}</span>
                          </p>
                        )}
                      </>
                    )}
                    {buttonStage === "picking" && (
                      <>
                        {formattedDate && (
                          <p className="">
                            <Calendar
                              className="text-amber-600"
                              size={14}
                              style={{ marginRight: "5px" }}
                            />
                            <span>Selected Date :-{formattedDate}</span>
                          </p>
                        )}
                      </>
                    )}
                  </div>

                  <div className="SchdulBttnDiv">
                    <div className="button-group">
                      {buttonStage === "picking" && (
                        <div className="save_edit_clndrr">
                          {isEditing ? (
                            <>
                              {/* <Button className="upcoming-action save-btn" onClick={handleCLoseDatePicker}>
                                <Check size={16} style={{ marginRight: '5px' }} />
                                Save
                              </Button>
                              <Button className="upcoming-action cancel-btn" onClick={handleCancelEdit}>
                                <X size={16} style={{ marginRight: '5px' }} />
                                Cancel
                              </Button> */}
                            </>
                          ) : (
                            <>
                              <Button
                                className="upcoming-action"
                                onClick={handleSaveDate}
                              >
                                <Check
                                  size={16}
                                  style={{ marginRight: "5px" }}
                                />
                                Save Date
                              </Button>

                              {!showDatePicker && (
                                <Button
                                  className="upcoming-action"
                                  onClick={handleEditClick}
                                >
                                  <Edit
                                    size={16}
                                    style={{ marginRight: "5px" }}
                                  />
                                  Change Date
                                </Button>
                              )}
                            </>
                          )}



                          {showDatePicker && (
                            <div className="datepicker-container">
                              <DatePicker
                                selected={selectedDate}
                                onChange={handleDateChange}
                                minDate={new Date()}
                                maxDate={
                                  new Date(
                                    new Date().setDate(new Date().getDate() + 5)
                                  )
                                }
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                inline
                                onSelect={handleDateSelect}
                                dateFormat="dd-MM-yyyy"
                              />
                            </div>
                          )}
                        </div>
                      )}

                      {buttonStage === "initial" && (
                        <Button
                          className="upcoming-action"
                          onClick={handleMainButtonClick}
                        >
                          Reschedule Maintenance{" "}
                          <ChevronRight className="icon" />
                        </Button>
                      )}
                    </div>

                    {/* {buttonStage === "scheduled" && (
                      <Button
                        className="reschedule-action"
                        onClick={handleRescheduleClick}
                      >
                        Reschedule ({formattedDate})
                      </Button>
                    )} */}

                  </div>
                </div>
              </div>

              {/**-----------------Payment Deatail Card--------- */}
              <div className="col-lg-6 mb-3">
                <div className="paymentCardContrr">   <Card className="payment-card">
                  <div className="payment-content">

                    <div>
                      <h5 className="upcoming_due_py">
                        {" "}
                        <Clock size={19} /> Upcoming Due Payment
                      </h5>
                      <div className="amount_paymnt_contr">
                        {" "}
                        <div className="col-6">
                          {" "}
                          <div className="payment-amount">
                            {/* <span className="amount-label">Amount</span> */}
                            <span className="amount-value">$249.00</span>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="col-4">  <Button className="pay-button">Pay Now</Button></div>

                  </div>
                </Card></div>



              </div>
            </div>
          </div>
          <div className="dashboard-tabs">
            {/* <div className="tab-buttons">
              <button
                className={`tab-button ${activeTab === "payments" ? "active" : ""
                  }`}
                onClick={() => setActiveTab("payments")}
              >
                <CreditCard className="tab-icon" />
                Payment History
              </button>
              <button
                className={`tab-button ${activeTab === "maintenance" ? "active" : ""
                  }`}
                onClick={() => setActiveTab("maintenance")}
              >
                <Tool className="tab-icon" />
                Maintenance Records
              </button>
            </div> */}
            <PaymentHistrory paymentHistryData={paymentHistryData} />
            {/* <div className="tab-content">
              {activeTab === "payments" && (
                <PaymentHistrory paymentHistryData={paymentHistryData} />
              )}

              {activeTab === "maintenance" && (
                <MaintenanceRecord
                  maintainRecord={maintainRecord}
                  carData={carData}
                />
              )}
            </div> */}
          </div>
        </div>
      </div>
    </Layout>
  );
}
