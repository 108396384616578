import "bootstrap/dist/css/bootstrap.min.css";
import { Camera, CheckCircle, Eye, EyeOff } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { handleAphabetsChange, handleError, handleNumbersChange } from "../../CommonJquery/CommonJquery";
import {
  APL_LINK,
  change_password_main,
  get_profile_data,
  save_profile,
  server_post_data
} from "../../ServiceConnection/serviceconnection";
import "../CustomCSS/Profile.css";
import Layout from "../Layout/Layout";
const Profile = () => {
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    mobile: "",
    old_img_profile: "",
  });

  const [formData2, setFormData2] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState({
    current: false,
    new: false,
    confirm: false,
  });
  const [errors, setErrors] = useState({
    email: "",
    name: "",
    mobile: "",
  });
  const [showSuccessModalProfileUpd, setShowSuccessModalProfileUpd] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [ProfileData, setProfileData] = useState([]);
  const [profileImage, setProfileImage] = useState(null);
  const [profileImageAppend, setProfileImageAppend] = useState(null);
  const fileInputRef = useRef(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setIsEdited(true);
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: ''
      });
    }

  };



  const handleEmailChange = (e) => {
    setFormData({ ...formData, email: e.target.value });
  };
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [passwordFormatError, setPasswordFormatError] = useState(false);
  const [passwordSameError, setPasswordSameError] = useState(false);
  const [emptyFieldError, setEmptyFieldError] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const [isEdited, setIsEdited] = useState(false);
  const [activeTab, setActiveTab] = useState("profile");



  const handleImageClick = () => {
    fileInputRef.current?.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files?.[0];
    setProfileImageAppend(file)
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setProfileImage(imageUrl);
      setIsEdited(true);
    }
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    const fd = new FormData();
    // fd.append("flag", 1);
    await server_post_data(get_profile_data, fd)
      .then((Response) => {

        if (Response.error) {
          handleError(Response.message);
        } else {
          setProfileData(Response.message.data);
          setFormData({
            email: Response.message.data.email,
            name: Response.message.data.name,
            mobile: Response.message.data.mobile_no,
            old_img_profile: Response.message.data.user_image,
          });
          setProfileImage(APL_LINK + Response.message.image_link + Response.message.data.user_image)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSaveProfileData = (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!formData.name || formData.name.trim() === '') {
      newErrors.name = 'Please fill this field';
    }

    if (!formData.email || formData.email.trim() === '') {
      newErrors.email = 'Please fill this field';
    }

    if (!formData.mobile || formData.mobile.trim() === '') {
      newErrors.mobile = 'Please fill this field';
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    handleSaveChangesdynamic();

  };

  console.log("profileImageAppend", profileImageAppend)

  const handleSaveChangesdynamic = async () => {

    let fd_from = new FormData();
    fd_from.append("firstName ", formData.name);
    fd_from.append("email", formData.email);
    fd_from.append("phone", formData.mobile);
    fd_from.append("profile_image_old", formData.old_img_profile);
    if (profileImageAppend !== null && profileImageAppend !== undefined) {
      fd_from.append("profile_image", profileImageAppend);
    }

    await server_post_data(save_profile, fd_from)
      .then((Response) => {

        if (Response.error) {
          console.log(Response.data.message);
        } else {
          setShowSuccessModalProfileUpd(true)
        }
      })
      .catch((error) => {
        console.log(error);


      });

  };
  const [currentPassError, setCurrentPassError] = useState("");
  const handleSaveChangesdynamicSendOtp = async () => {

    let fd_from = new FormData();
    fd_from.append("old_password ", formData2.currentPassword);
    fd_from.append("password", formData2.confirmPassword);
    await server_post_data(change_password_main, fd_from)
      .then((Response) => {
        console.log(Response.message);
        if (Response.error) {

          setCurrentPassError(Response.message)
        } else {
          setCurrentPassError("")
          setShowSuccessModal(true);
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/login";
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error);


      });

  };
  const togglePassword = (field) => {
    setShowPassword((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    setCurrentPassError("")
    if (name === "newPassword" || name === "confirmPassword") {
      setPasswordMatchError(false);
    }
    // Reset empty field errors as the user types
    setEmptyFieldError((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));

    // Password format check for new password
    if (name === "newPassword") {
      if (value.trim() === "") {
        setPasswordFormatError(false);
      } else {
        const passwordRegex = /(?=.*[0-9])(?=.*[\W_])/;
        setPasswordFormatError(!passwordRegex.test(value));
      }
      setPasswordSameError(false);
    }
    setFormData2({
      ...formData2,
      [name]: value,
    });
  };



  const validateForm = () => {
    let isValid = true;
    let fieldErrors = { ...emptyFieldError };
    const passwordRegex = /(?=.*[0-9])(?=.*[\W_])/;
    for (const field in formData2) {
      if (formData2[field].trim() === "") {
        fieldErrors[field] = true;
        isValid = false;
      } else {
        fieldErrors[field] = false;
      }
    }
    // Password format validation
    if (formData2.newPassword.trim() !== "") {
      if (!passwordRegex.test(formData2.newPassword)) {
        setPasswordFormatError(true);
        isValid = false;
      } else {
        setPasswordFormatError(false);
      }
    } else {
      setPasswordFormatError(false);
    }
    if (formData2.newPassword !== formData2.confirmPassword) {
      setPasswordMatchError(true);
      isValid = false;
    } else {
      setPasswordMatchError(false);
    }
    // Current password and new password should not be the same.
    if (formData2.newPassword.trim() !== "") {
      if (formData2.currentPassword === formData2.newPassword) {
        setPasswordSameError(true);
        isValid = false;
      } else {
        setPasswordSameError(false);
      }
    } else {
      setPasswordMatchError(false);
    }

    setEmptyFieldError(fieldErrors);
    return isValid;
  };


  const sendOtp = () => {
    if (validateForm()) {
      handleSaveChangesdynamicSendOtp()
    }
  };

  /// Profile Update Modal  Modal Show
  useEffect(() => {
    if (showSuccessModalProfileUpd) {
      const timer = setTimeout(() => {
        setShowSuccessModalProfileUpd(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [showSuccessModalProfileUpd]);



  /// changed  Password  Modal Show
  useEffect(() => {
    if (showSuccessModal) {
      const timer = setTimeout(() => {
        setShowSuccessModal(false);
      }, 1000); // Close modal after 1 second
      return () => clearTimeout(timer);
    }
  }, [showSuccessModal]);

  return (
    <>
      <Layout >
        <div className="Profile_co0ntrr" style={{ overflow: "hidden" }}>
          <div className="profileHeading">
            <h2>Profile</h2>

            <div className="container-lg">
              <div className="ProfileTabbs">
                <button
                  className={activeTab === "profile" ? "active" : ""}
                  onClick={() => setActiveTab("profile")}
                >
                  Account Details
                </button>
                <button
                  className={activeTab === "password" ? "active" : ""}
                  onClick={() => setActiveTab("password")}
                >
                  Change Password
                </button>
              </div>
            </div>
          </div>

          <div className="profiledataContrr">
            <div className="container-lg">
              {activeTab === "profile" && (
                <form className="form-cnfrom" noValidate onSubmit={handleSaveProfileData}>

                  <div className="profile_img_section">
                    <div className="profile-image-container">
                      <div onClick={handleImageClick} className="profile-image-upload">
                        {profileImage ? (
                          <img src={profileImage || "/placeholder.svg"} alt="Profile" className="profile-image" />
                        ) : (
                          <div className="upload-icon">
                            <Camera size={32} />
                            <span className="upload-text">Upload Photo</span>
                          </div>
                        )}
                        <input type="file" ref={fileInputRef} onChange={handleImageChange} className="hidden" accept="image/*" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="email" className="form-label">
                        Email Address
                      </label>
                      <input
                        maxLength={50}
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        defaultValue={formData.email}
                        onChange={handleChange}
                        readOnly
                        required
                        onInput={handleEmailChange}
                      />
                      {errors.email && <span className="error-text">{errors.email}</span>}
                    </div>

                    <div className="col-md-6 mb-3">
                      <label htmlFor="name" className="form-label">
                        Name
                      </label>
                      <input
                        maxLength={50}
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        defaultValue={formData.name}
                        onChange={handleChange}

                        required
                        onInput={handleAphabetsChange}
                      />
                      {errors.name && <span className="error-text">{errors.name}</span>}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="mobile" className="form-label">
                        Mobile Number
                      </label>
                      <input
                        maxLength={10}
                        type="text"
                        className="form-control"
                        id="mobile"
                        name="mobile"
                        defaultValue={formData.mobile}
                        onChange={handleChange}

                        onInput={handleNumbersChange}
                        required

                      />
                      {errors.mobile && <span className="error-text">{errors.mobile}</span>}
                    </div>
                  </div>
                  <div className="col-md-4 SubmtBttnClass">
                    <button

                      type="submit"
                      className="btn btn-primary w-100"
                      style={{ opacity: isEdited ? 1 : 0.5 }}
                      disabled={!isEdited}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              )}

              {activeTab === "password" && (
                <form className="form-cnfrom" noValidate>
                  <div className="row">
                    <span className="error-text">
                      {passwordMatchError && "Passwords do not match"}
                    </span>
                    <span className="text-danger" style={{ fontSize: "12px" }}>
                      {currentPassError}
                    </span>
                    {/**------------Password Check Credintial------------- */}
                    {passwordFormatError && (
                      <p className="error-text MargnClssCndiomn" style={{ fontSize: "12px" }}>
                        Password must contain at least 1 special character<span > (like @, #, $, !, etc.)  and 1 number </span>
                      </p>
                    )}
                    {/**-----------------Curent and New password not same------------------ */}
                    {passwordSameError && (
                      <span className="error-text">
                        Current Password and New Password are the same. Please choose a different new password.
                      </span>
                    )}
                    <div className="col-md-6 mb-3">


                      <label htmlFor="email" className="form-label">
                        Current Password
                      </label>
                      <div className="position-relative">
                        <input
                          type={showPassword.current ? "text" : "password"}
                          className="form-control"
                          name="currentPassword"
                          placeholder="Enter Current Password"
                          value={formData2.currentPassword}

                          onChange={handleChange2}
                          required
                        />
                        <span
                          className="position-absolute" style={{ right: "10px", top: "50%", transform: "translateY(-50%)", cursor: "pointer" }}
                          onClick={() => togglePassword("current")}
                        >
                          {showPassword.current ? <EyeOff size={15} /> : <Eye size={15} />}
                        </span>
                      </div>
                      {emptyFieldError.currentPassword && (
                        <span className="text-danger" style={{ fontSize: "12px" }}>
                          Please fill this field
                        </span>
                      )}
                    </div>
                    <div className="col-md-6 mb-3"></div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="email" className="form-label">
                        New Password
                      </label>
                      <div className="position-relative">
                        <input
                          type={showPassword.new ? "text" : "password"}
                          className="form-control"
                          name="newPassword"
                          placeholder="Enter New Password"
                          value={formData2.newPassword}
                          onChange={handleChange2}

                          required
                        />
                        <span
                          className="position-absolute" style={{ right: "10px", top: "50%", transform: "translateY(-50%)", cursor: "pointer" }}
                          onClick={() => togglePassword("new")}
                        >
                          {showPassword.new ? <EyeOff size={15} /> : <Eye size={15} />}
                        </span>
                      </div>
                      {emptyFieldError.newPassword && (
                        <span className="text-danger" style={{ fontSize: "12px" }}>
                          Please fill this field
                        </span>
                      )}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="email" className="form-label">
                        Confirm Password
                      </label>
                      <div className="position-relative">
                        <input
                          type={showPassword.confirm ? "text" : "password"}
                          className="form-control"
                          name="confirmPassword"
                          placeholder="Enter Confirm Password"
                          value={formData2.confirmPassword}
                          onChange={handleChange2}

                          required
                        />
                        <span
                          className="position-absolute" style={{ right: "10px", top: "50%", transform: "translateY(-50%)", cursor: "pointer" }}
                          onClick={() => togglePassword("confirm")}
                        >
                          {showPassword.confirm ? <EyeOff size={15} /> : <Eye size={15} />}
                        </span>
                      </div>
                      {emptyFieldError.confirmPassword && (
                        <span className="text-danger" style={{ fontSize: "12px" }}>
                          Please fill this field
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4 SubmtBttnClass">
                    <button
                      type="button"
                      className="btn btn-primary w-100"
                      onClick={sendOtp}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>


        </div>
      </Layout>
      {/**---------------------Profile Update Modal------------------ */}
      <Modal show={showSuccessModalProfileUpd} onHide={() => setShowSuccessModalProfileUpd(false)} centered>
        <Modal.Body className="text-center p-4">
          <CheckCircle size={64} className="text-success mb-3" />
          <h4 className="paschngertxt2">
            Your profile has been successfully updated!
          </h4>
        </Modal.Body>
      </Modal>
      {/**---------------------Changed Password Upadte Modal------------------ */}
      <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered>
        <Modal.Body className="text-center p-4">
          <CheckCircle size={64} className="text-success mb-3" />
          <h4 className="paschngertxt">Password is successfully changed</h4>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Profile;
