import axios from "axios";
import { retrieveData } from "../LocalConnection/LocalConnection.js";

const appauth_key = "www.rapidcarleasing.com";
let APL_LINK = "http://192.168.1.8:8000/";
APL_LINK = "https://backend.rapidcarleasing.com/";
// let Website_URL = "https://www.rapidcarleasing.com/";

const local_server_link_react = APL_LINK + "api/website_link/";

// Login Api
const user_registation_login =
  local_server_link_react + "user_registation_login";

// Post Apis
const save_subs = local_server_link_react + "save_subs";
const save_contact = local_server_link_react + "save_contact";
const getImage = local_server_link_react + "getImage";
const save_booking = local_server_link_react + "save_booking";
const google_login = local_server_link_react + "google_login";

// Get Apis
const get_booking_data_website =
  local_server_link_react + "get_booking_data_website";
const get_blog_data_website = local_server_link_react + "get_blog_data_website";
const blog_details_website = local_server_link_react + "blog_details_website";
const get_faq_testimonial_data =
  local_server_link_react + "get_faq_testimonial_data";
const view_all_carmodel_type =
  local_server_link_react + "view_all_carmodel_type";
const get_all_cars_website = local_server_link_react + "get_all_cars_website";
const details_cars_website = local_server_link_react + "details_cars_website";
const save_review = local_server_link_react + "save_review";
const get_favorite_car_website =
  local_server_link_react + "get_favorite_car_website";
const save_delete_get_favorite =
  local_server_link_react + "save_delete_get_favorite";
const get_footer_data_website =
  local_server_link_react + "get_footer_data_website";
const check_resetlink_vaild = local_server_link_react + "check_resetlink_vaild";
const change_password_by_forgot = local_server_link_react + "Change_Password";
const forgot_password_user = local_server_link_react + "forgot_password_user";
const maintenance_data_user = local_server_link_react + "maintenance_data_user";
const save_booking_Aggrement =
  local_server_link_react + "save_booking_Aggrement";
const get_booking_data_main = local_server_link_react + "get_booking_data_main";
const save_image_pdf = local_server_link_react + "save_image_pdf";
const save_profile = local_server_link_react + "save_profile";
const get_profile_data = local_server_link_react + "get_profile_data";
const change_password_main = local_server_link_react + "change_password_main";
const create_payment_intent = local_server_link_react + "create_payment_intent";
const create_checkout_session = local_server_link_react + "create_checkout_session";
const server_post_data = async (url_for, Data) => {
  try {
    if (Data === null) {
      Data = new FormData();
    }

    let customer_id = retrieveData("customer_id");
    if (customer_id === null || customer_id === 0) {
      customer_id = 0;
    }

    let token = retrieveData("Auth_token");
    if (token === null || token === 0) {
      token = 0;
    }

    Data.append("web_app", "Reactnative");
    Data.append("platform_type", "website");
    Data.append("cd_id", customer_id);
    // console.log("dhasiuhgdkjashd", token);
    const response = await axios.post(url_for, Data, {
      headers: {
        "Content-Type": "multipart/form-data", // If sending FormData
        Authorization: `Bearer ${token}`,
        // Include other headers as needed for your backend
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export {
  APL_LINK,
  blog_details_website,
  change_password_by_forgot,
  check_resetlink_vaild,
  details_cars_website,
  forgot_password_user,
  get_all_cars_website,
  get_blog_data_website,
  get_booking_data_main,
  get_booking_data_website,
  get_faq_testimonial_data,
  get_favorite_car_website,
  get_footer_data_website,
  getImage,
  google_login,
  maintenance_data_user,
  save_booking,
  save_booking_Aggrement,
  save_contact,
  save_delete_get_favorite,
  save_review,
  save_subs,
  server_post_data,
  user_registation_login,
  view_all_carmodel_type,
  save_image_pdf,
  save_profile,
  get_profile_data,
  change_password_main,
  create_payment_intent,
  create_checkout_session,
};
