import {
  ArrowDown,
  ArrowUp,
  Calendar,
  CheckSquare,
  DollarSign,
} from "lucide-react";
import React, { useEffect, useState } from "react";

import { formatDateString } from "../../CommonJquery/CommonJquery";
const PaymentHistrory = ({ paymentHistryData }) => {
  const [showFullID, setShowFullID] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(paymentHistryData);
  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredData(paymentHistryData);
    } else {
      const lowercasedSearch = searchTerm.toLowerCase();
      const filtered = paymentHistryData.filter((payment) => {
        const amountMatch = payment.ledger_price
          .toString()
          .includes(lowercasedSearch);
        const date = formatDateString(payment.entry_date).toLowerCase();
        const dateMatch = date.includes(lowercasedSearch);
        const paymentModeMatch = payment.payment_mode
          .toLowerCase()
          .includes(lowercasedSearch);

        const transactionIdMatch = payment.transaction_id
          .toLowerCase()
          .includes(lowercasedSearch);

        return (
          amountMatch || dateMatch || paymentModeMatch || transactionIdMatch
        );
      });

      setFilteredData(filtered);
    }
  }, [searchTerm, paymentHistryData]);
  const toggleIDVisibility = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const totalPaid = paymentHistryData.reduce(
    (total, payment) => total + payment.ledger_price,
    0
  );

  const highestPayment = Math.max(
    ...paymentHistryData.map((payment) => payment.ledger_price)
  );

  const completedPaymentsCount = paymentHistryData.filter(
    (payment) => !["Failed", "Card"].includes(payment.status)
  ).length;

  return (
    <div>
      {" "}
      <div className="payments-tab">
        <div className="tab-header">
          <h3>Payment History</h3>
          {/* <div className="tab-actions">
            <div className="search-container">
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search by amount, date, payment mode, or transaction ID..."
                className="search-input"
              />
            </div>
          </div> */}
        </div>

        <div className="row ">
          <div className="paymentCards1">
            {" "}
            <div className="summary-card">
              <div className="d-flex align-items-center">
                <div className="summary-icon">
                  <DollarSign />
                </div>
                <h4 className="summary-contentHead">Total Payment</h4>
              </div>
              <div className="summary-content">
                <p>$ {totalPaid}</p>
              </div>
            </div>
          </div>
          <div className="paymentCards1">
            {" "}
            <div className="summary-card">
              <div className="d-flex align-items-center">
                <div className="summary-icon">
                  <ArrowUp />
                </div>
                <h4 className="summary-contentHead">Car Rent</h4>
              </div>
              <div className="summary-content">
                <p>${highestPayment}</p>
              </div>
            </div>
          </div>
          <div className="paymentCards1">
            {" "}
            <div className="summary-card">
              <div className="d-flex align-items-center">
                <div className="summary-icon">
                  <CheckSquare />
                </div>
                <h4 className="summary-contentHead">I-Pass</h4>
              </div>
              <div className="summary-content">
                <p>${completedPaymentsCount}</p>
              </div>
            </div>
          </div>
          <div className="paymentCards1">
            {" "}
            <div className="summary-card">
              <div className="d-flex align-items-center">
                <div className="summary-icon">
                  <CheckSquare />
                </div>
                <h4 className="summary-contentHead">Maintenance</h4>
              </div>
              <div className="summary-content">
                <p>${completedPaymentsCount}</p>
              </div>
            </div>
          </div>
          <div className="paymentCards1">
            {" "}
            <div className="summary-card">
              <div className="d-flex align-items-center">
                <div className="summary-icon">
                  <CheckSquare />
                </div>
                <h4 className="summary-contentHead">Due Amount</h4>
              </div>
              <div className="summary-content">
                <p>${completedPaymentsCount}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="table-container">
          <table className="data-table payments-table">
            <thead>
              <tr>
                <th>
                  <div className="th-content">
                    <span>Sr.No.</span>
                    <div className="sort-icons">
                      <ArrowUp className="sort-icon" />
                      <ArrowDown className="sort-icon" />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-content">
                    <span>Date</span>
                    <div className="sort-icons">
                      <ArrowUp className="sort-icon" />
                      <ArrowDown className="sort-icon" />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-content">
                    <span>Payment For</span>
                    <div className="sort-icons">
                      <ArrowUp className="sort-icon" />
                      <ArrowDown className="sort-icon" />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-content">
                    <span>Amount</span>
                    <div className="sort-icons">
                      <ArrowUp className="sort-icon" />
                      <ArrowDown className="sort-icon" />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-content">
                    <span>Payment Mode</span>
                    <div className="sort-icons">
                      <ArrowUp className="sort-icon" />
                      <ArrowDown className="sort-icon" />
                    </div>
                  </div>
                </th>
                {/* <th>
                  <div className="th-content">
                    <span>Transaction ID</span>
                    <div className="sort-icons">
                      <ArrowUp className="sort-icon" />
                      <ArrowDown className="sort-icon" />
                    </div>
                  </div>
                </th> */}
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 ? (
                filteredData.map((payment, index) => (
                  <tr key={index} className="data-row">
                    <td>
                      <div className="id-cell">#{index + 1}</div>
                    </td>
                    <td>
                      <div className="date-cell">
                        <Calendar className="cell-icon" />
                        {formatDateString(payment.entry_date)}
                      </div>
                    </td>
                    <td>
                      <div className="amount-cell">₹{payment.ledger_price}</div>
                    </td>
                    <td>
                      <div className="amount-cell">₹{payment.ledger_price}</div>
                    </td>
                    <td>
                      <div className="payment-mode-cell">
                        <span
                          className={`payment-mode ${payment.payment_mode
                            .toLowerCase()
                            .replace(" ", "-")}`}
                        >
                          {payment?.payment_mode || "NA"}
                        </span>
                      </div>
                    </td>
                    {/* <td>
                      <div className="transaction_id">
                        <Banknote
                          className="w-5 h-5 text-green-500 me-2"
                          strokeWidth={1}
                        />
                        {expandedRows[index] ? (
                          <>
                            {payment.transaction_id}{" "}
                            <span
                              className="text-blue-500 cursor-pointer lessbttn_"
                              onClick={() => toggleIDVisibility(index)}
                            >
                              Less
                            </span>
                          </>
                        ) : (
                          <>
                            {payment.transaction_id.slice(0, 5)}
                            <span
                              className="text-blue-500 cursor-pointer descraseTxt"
                              onClick={() => toggleIDVisibility(index)}
                            >
                              ...
                            </span>
                          </>
                        )}
                      </div>
                    </td> */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center py-4">
                    No matching payment records found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="table-footer">
          <div className="pagination">
            <button className="pagination-btn prev">Previous</button>
            <div className="pagination-pages">
              <button className="pagination-page active">1</button>
              <button className="pagination-page">2</button>
              <button className="pagination-page">3</button>
            </div>
            <button className="pagination-btn next">Next</button>
          </div>
          <div className="items-per-page">
            <span>Show:</span>
            <select className="items-select">
              <option>10</option>
              <option>20</option>
              <option>50</option>
            </select>
            <span>items</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentHistrory;
