import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import RightArow from "../../Assests/imgs/template/icons/arrow-right.svg";
import { formatDateString, handleError } from "../../CommonJquery/CommonJquery";
import {
  APL_LINK,
  get_blog_data_website,
  server_post_data,
} from "../../ServiceConnection/serviceconnection";
import Layout from "../Layout/Layout";

const Blogs = () => {
  const swiperGroup1 = {
    modules: [Autoplay, Pagination, Navigation],
    slidesPerView: 1,
    spaceBetween: 50,
    slidesPerGroup: 1,
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination-group-1",
      clickable: true,
    },
    autoplay: {
      delay: 100000,
    },
  };

  const [showLoaderAdmin, setShowLoaderAdmin] = useState(false);
  const [blogData, setBlogData] = useState([]);
  const [SEOloop, setSEOloop] = useState([]);
  const [imgLink, setImgLink] = useState();

  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "/Blogs/blogdetail/" + v_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setShowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("flag", 1);
    await server_post_data(get_blog_data_website, fd)
      .then((Response) => {
        if (Response.error) {
          handleError(Response.message);
        } else {
          setBlogData(Response.message.blog_list);
          setSEOloop(Response.message.seo_loop);
          setImgLink(APL_LINK + Response.message.blog_image);
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoaderAdmin(false);
      });
  };
  return (
    <Layout>
      <section className="box-section background-body pt-80">
        <div className="container">
          <div className="text-center  mb-40">
            <div className="background-body px-3 py-2 rounded-12 border d-flex gap-3 d-inline-flex">
              <Link to="/" className="neutral-700 text-md-medium">
                Home
              </Link>
              <span>
                <img src={RightArow} alt="Carento" />
              </span>
              <Link className="neutral-1000 text-md-bold">Blog</Link>
            </div>
            <h3 className="my-3 neutral-1000">Inside &amp; Trending</h3>
          </div>
          <div className="box-swiper">
            <Swiper
              {...swiperGroup1}
              className="swiper-container swiper-group-1 position-relative"
            >
              {blogData.slice(0, 3).map((slide, index) => (
                <SwiperSlide key={index}>
                  <div
                    className={`item-banner-slide-review d-flex align-items-center rounded-12`}
                    style={{ backgroundImage: `url(${slide.image_url})` }}
                  >
                    <div className="ps-md-5 ps-2 position-relative z-1">
                      <span className="text-primary text-md-bold">
                        {slide.keyword}
                      </span>
                      <h3 className="mt-20 mb-20 color-white">
                        {slide.title_name}
                        <br className="d-none d-md-block" />
                      </h3>
                      <div className="card-meta-user">
                        <div className="box-author-small">
                          <img src={slide.author_image} alt={slide.author} />
                          <p className="text-sm-bold">By {slide.author}</p>
                        </div>
                        <div className="date-post">
                          <p className="text-sm-medium">
                            {formatDateString(slide.entry_date)}
                          </p>
                        </div>
                      </div>
                      <p className="text-lg-medium color-white mt-3">
                        {slide.tag_line}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
              <div className="position-absolute end-0 bottom-0 p-40">
                <div className="box-button-slider box-button-slider-team justify-content-end">
                  <div
                    className="swiper-button-prev swiper-button-prev-style-1 swiper-button-prev-2"
                    tabIndex={0}
                    role="button"
                    aria-label="Previous slide"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M7.99992 3.33325L3.33325 7.99992M3.33325 7.99992L7.99992 12.6666M3.33325 7.99992H12.6666"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div
                    className="swiper-button-next swiper-button-next-style-1 swiper-button-next-2"
                    tabIndex={0}
                    role="button"
                    aria-label="Next slide"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M7.99992 12.6666L12.6666 7.99992L7.99992 3.33325M12.6666 7.99992L3.33325 7.99992"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </Swiper>
          </div>
          <h3 className="text-center neutral-1000 marginClass ">Latest News</h3>
          <div className="row mb-4">
            {blogData.map((post, index) => (
              <div key={index} className="col-lg-4 col-md-6 col-12 mt-3">
                <Link to={match_and_return_seo_link(post.primary_id)}>
                  <div className="card-news background-card hover-up mb-4 heightClass">
                    <div className="card-image">
                      <img
                        className="maxHeightClassBLog"
                        src={post.image_url}
                        alt="Carento"
                      />
                    </div>
                    <div className="card-info">
                      <label className="bg-2 rounded-12 position-absolute top-0 end-0 translate-middle-y px-3 py-2 me-4 text-sm-bold">
                        {post.keyword}
                      </label>
                      <div className="card-meta">
                        <span className="post-date neutral-1000">
                          {formatDateString(post.entry_date)}
                        </span>
                        <span className="post-time neutral-1000">
                          {post.read_minit} mins
                        </span>
                      </div>
                      <div className="card-title">
                        <Link
                          className="truncate-title text-xl-bold neutral-1000"
                          to={match_and_return_seo_link(post.primary_id)}
                        >
                          {window.innerWidth <= 768
                            ? post.title_name
                            : post.title_name.split(" ").slice(0, 6).join(" ") +
                              (post.title_name.split(" ").length > 6
                                ? "..."
                                : "")}
                        </Link>
                      </div>

                      <div className="card-program">
                        <div className="endtime">
                          <div className="card-author">
                            <img
                              style={{ width: "32px", height: "32px" }}
                              className="rounded-circle border border-primary"
                              src={post.author_image}
                              alt="Carento"
                            />
                            <p className="text-sm-bold neutral-1000">
                              {post.author}
                            </p>
                          </div>
                          <div className="card-button">
                            <Link
                              className="btn btn-gray"
                              to={match_and_return_seo_link(post.primary_id)}
                            >
                              Keep Reading
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Blogs;
