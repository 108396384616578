import { ArrowRight } from "lucide-react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../Assests/css/mybooking.css";
import { formatDateStringdot } from "../../CommonJquery/CommonJquery";
function BookingCard({ datass, image, key }) {
  const navigate = useNavigate();

  const openAgreementForm = (event) => {
    //event.stopPropagation();
    navigate("/agreement", {
      state: { bookingId: datass.primary_id, carId: datass.car_id },
    });
  };

  const handleNavigate = () => {
    console.log("handleNavigate called");
    console.log("datass:", datass);

    if (!datass || !datass.primary_id) {
      console.error("Invalid booking data:", datass);
      return;
    }

    navigate("/maintenance", {
      state: {
        bookingData: datass,
        bookingId: datass.primary_id,
      },
    });
    console.log("Navigation executed!");
  };

  console.log("datass", datass);
  return (
    <>
      {/* {datass && datass.length > 0 && ( */}
      <div className="booking-card justify-content-between" key={key}>
        <div className="d-flex align-items-center">
          <Link>
            <img
              src={`${image}${datass.image}`}
              alt="Car"
              className="car-image carImageBooking"
            />
          </Link>

          <div className="booking-info">
            <p className="elepsisClassPTop">
              From: {formatDateStringdot(datass.from_date)} - To:{" "}
              {formatDateStringdot(datass.end_date)}
            </p>
            <h3 className="elepsisClass">{datass.car_name}</h3>
            <p className="elepsisClassP">{datass.car_no}</p>


          </div>

        </div>
        {datass.step_for_booking == 0 && (
          <div className="Mobile-status">
            <span className="full-text-label"></span>
            <button className="action-button-booking actioonBtn2 bgPending">
              Under Process
            </button>
          </div>
        )}
        {datass.step_for_booking == 1 && (
          <div className="Mobile-status">
            <span className="full-text-label"></span>
            <button className="action-button-booking actioonBtn2 bgAprved">
              Insurance Under Review
            </button>
          </div>
        )}
        {datass.step_for_booking == 2 && (
          <div className="Mobile-status">
            <span className="full-text-label"></span>

            {datass.fill_aggriment_status == 0 && (
              <button className="action-button-booking actioonBtn2 bgOn">
                Insurance Approved
              </button>
            )}
            <div className="approval-section">
              {/* <p className="approval-text">
                Your insurance has been approved. Please submit your Agreement Form for the pickup vehicle.
              </p> */}
              {datass.fill_aggriment_status == 0 && (
                <button
                  className="agreement-button"
                  onClick={openAgreementForm}
                >
                  Sign Agreement
                </button>
              )}
              {datass.fill_aggriment_status == 1 && (
                <button className="action-button-booking actioonBtn2 bgOn">
                  Agreement Signed
                </button>
              )}
            </div>
          </div>
        )}
        {datass.step_for_booking == 3 && (
          <div className="Mobile-status">
            <span className="full-text-label"></span>
            <button className="action-button-booking actioonBtn2 bgCompleted">
              Ready for Pickup
            </button>
           


          </div>
        )}
        {datass.step_for_booking == 4 && (
          <div className="Mobile-status">
            <span className="full-text-label"></span>
            <button className="action-button-booking actioonBtn2 bgCompleted">
              Waiting For Drop
            </button>
            <button className="action-button-booking actioonBtn2 bgCompleted DetailBtn" onClick={handleNavigate}>
              View Details <ArrowRight size={17} strokeWidth={1.5} />
            </button>
          </div>
        )}
        {datass.step_for_booking == 10 && (
          <div className="Mobile-status">
            <span className="full-text-label"></span>
            <button className="action-button-booking actioonBtn2 bgCancelled">
              Rejected
            </button>
          </div>
        )}
        {datass.step_for_booking == 5 && (
          <div className="Mobile-status">
            <span className="full-text-label"></span>
            <button className="action-button-booking actioonBtn2 bgCancelled">
              Completed
            </button>
          </div>
        )}
      </div>

      {/* )} */}
    </>
  );
}

export default BookingCard;
