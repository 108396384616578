import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  APL_LINK,
  create_payment_intent,
  create_checkout_session,
  server_post_data,
} from "../../ServiceConnection/serviceconnection";
import { handleError } from "../../CommonJquery/CommonJquery";
const stripePromise = loadStripe(
  "pk_test_51PVU9cP7GdOA24IdEhPhESdS6asxh2Ctyf28ru6lB2PrFInUsOwN97ThBPqp4OkLnxfkSZy3IEtIJIDrZaN1wKSb00s5VlmoGS"
);

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const stripe = await stripePromise;

    const fd = new FormData();
    fd.append("payment_amount", "5000000");
    const Response = await server_post_data(create_checkout_session, fd);
    console.log(Response);
    if (Response.error) {
      handleError(Response.message);
      setMessage(Response.error);
      setLoading(false);
      return;
    } else {
    }

    const confirmPayment = await stripe.redirectToCheckout({
      sessionId: Response.message.clientSecret,
    });

    if (confirmPayment.error) {
      setMessage(confirmPayment.error.message);
    } else {
      setMessage("Payment Successful!");
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button type="submit" disabled={!stripe || loading}>
        {loading ? "Processing..." : "Pay"}
      </button>
      <p>{message}</p>
    </form>
  );
};

const StripeCheckout = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};

export default StripeCheckout;
